<template>
  <b-modal v-model="modalDiscount" :title=" $t('modalTitles.discounts') "
    content-class="card form-rounded-inputs card-ecart" centered header-class="card-header"
    @hidden="fnResetProductDiscount()" cancel-variant="danger" size="md" hide-header-close>
    <!-- PRODUCT INFO -->
    <product-card :product="productInfo"></product-card>
    <hr>
    <!-- STEPS -->
    <div v-if=" (this.productInfo.discounts && this.productInfo.discounts.length > 0) && step == 1 ">
      <div class="row my-4">
        <div class="col-6">
          <div class="row">
            <div class="col-auto ">
              <p>
                <span class="f-w-500" v-text=" $t('tables.from') +': '"></span>
              </p>
            </div>
            <div class="col-auto ml-auto">
              <p>
                <span v-text="$moment( this.discount.from , 'x' ).format( this.CONST.DATE_FORMATS.DD_MM_YYYY ) "></span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-auto">
              <p> <span class="f-w-500" v-text=" $t('tables.to') +': '"></span> </p>
            </div>
            <div class="col-auto ml-auto">
              <p v-if=" discount.unlimit ">
                <span class="text-success" v-text=" $t('general.form.unlimited') "></span>
              </p>
              <p v-else>
                <span v-text="$moment( discount.to , 'x' ).format( this.CONST.DATE_FORMATS.DD_MM_YYYY ) "></span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-auto">
              <p class="text-truncate">
                <span class="f-w-500"
                  v-text=" (discount.is_amount ? $t('general.form.amount') : $t('general.form.percentage') )+': '"></span>
              </p>
            </div>
            <div class="col-auto ml-auto">
              <button class="btn btn-success btn-xs " @click="step = 2">
                <span
                  v-text=" discount.is_amount ?  fnFormatPrice(discount.percent, productInfo.currency) :  (discount.percent + '%') "></span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row" v-if="discount.variant_ids.length > 0">
            <div class="col-auto">
              <p class="">
                <span class="f-w-500" v-text=" $t('tables.variants') +': '"></span>
              </p>
            </div>
            <div class="col-auto ml-auto">
              <button class="btn btn-success btn-xs" @click="step = 3">
                <span v-text=" discount.variant_ids.length"></span> <i class="fa fa-list-ol ml-1"> </i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <p class="text-right m-0">
        <span v-text="$t('tables.price') + ': ' "></span>
        <span class="text-line-through text-muted" v-text=" fnFormatPrice(productInfo.price, productInfo.currency) "
          v-show="discount.percent"></span>
        <span v-show="discount.percent"> - </span>
        <strong class="text-success"
          v-text=" fnFormatPrice( discount.is_amount ? (productInfo.price - discount.percent) : ( productInfo.price - ( discount.percent * productInfo.price / 100  ))  , productInfo.currency ) "></strong>
      </p>
    </div>
    <div v-else>
      <v-observer tag='form' ref="formCreateDiscountVariants" class=" animated fadeIn" @submit.prevent="fnNextStep()"
        v-show=" step == 2">
        <p class="text-center my-4">
          <strong v-text="$t('components.productDiscountModal.quickDiscount')">
          </strong>
        </p>
        <p class=" mb-3 ">
          <strong v-text="$t('tables.price') + ': ' "></strong>
          <span class="text-line-through text-muted" v-text=" fnFormatPrice(productInfo.price, productInfo.currency) "
            v-show="quickDiscount.discount"></span>
          <span v-show="quickDiscount.discount"> - </span>
          <strong class="text-success"
            v-text=" fnFormatPrice( ( productInfo.price - ( quickDiscount.discount * productInfo.price / 100  ))  , productInfo.currency ) "></strong>
          <br>
          <small class="text-muted" v-text="$t('components.productDiscountModal.variantsMessage') ">
          </small>
        </p>

        <div class="row mb-4">
          <div class="col-6">
            <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.percentage') ">
              <div class="form-group mb-0">
                <label for="" v-text="$t('general.form.percentage') "></label>
                <div class="form-check" v-for=" (percent,index) in percentages" :key="index"
                  :class=" { 'is-invalid':errors.length > 0 } ">
                  <input class="form-check-input" type="radio" :id="'percent'+ index" v-model="quickDiscount.discount"
                    :value="percent.value" checked>
                  <label class="form-check-label" :for="'percent'+ index" v-text="percent.label"> </label>
                </div>
                <div class=" invalid-feedback " v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>

            </v-validation>
          </div>
          <div class="col-6">
            <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.dateLimit') ">
              <div class="form-group mb-0">
                <label for="" v-text="$t('general.form.dateLimit')"> </label>
                <div class="form-check" v-for=" (date,index) in dates" :key="index"
                  :class=" { 'is-invalid':errors.length > 0 } ">
                  <input class="form-check-input" type="radio" :id="'date'+ index" v-model="quickDiscount.date"
                    :value="date.value" checked>
                  <label class="form-check-label" :for="'date'+ index" v-text="date.label"> </label>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </v-validation>
          </div>
        </div>

        <p class="text-right m-0 ">
          <button type="button" class="btn btn-link btn-sm p-0" @click="step= 3"
            v-text="$t('general.button.customDiscount')"></button>
        </p>

        <input type="submit" hidden>
      </v-observer>

      <v-observer tag="form" class="form-rounded-inputs" @submit.prevent="fnNextStep()" v-slot="{ validate }"
        ref="discountForm" v-show=" step == 3">
        <p class="text-center my-4">
          <strong v-text="$t('components.productDiscountModal.customDiscount')"></strong>
        </p>

        <div class="row mb-2">
          <div class="col-12 col-sm-12">
            <div class="row mb-2">
              <div class="col">
                <span
                  v-text=" discount.is_amount ? $t('general.form.amount') : ($t('general.form.percentage') + ` (${$t('general.form.minimum')} 5%)`) ">
                </span>
              </div>
              <div class="col-auto ">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="isAmount" v-model="discount.is_amount"
                    @change=" validate()  ">
                  <label class="form-check-label" for="isAmount" v-text="$t('general.form.isAmount')">
                  </label>
                </div>
              </div>
            </div>

            <div class="form-group">
              <v-validation rules="required" v-slot="{ errors }"
                :name=" discount.is_amount ? $t('general.form.amount') : $t('general.form.percentage') ">
                <div class="input-group">
                  <input type="number" min="5" :max=" discount.is_amount ? (productInfo.price * .9 ) : 90 "
                    class="form-control" v-model="discount.percent" :class=" { 'is-invalid':errors.length > 0 } ">
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fa" :class=" !discount.is_amount ? 'fa-percentage':'fa-dollar-sign' "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </v-validation>
            </div>


            <div
              v-if=" (discount.percent >= 5 && discount.percent < 100) || (discount.is_amount && discount.percent <= (productInfo.price * .9 ) )  ">
              <p>
                <strong v-text="$t('tables.price') + ': ' "></strong>
                <span class="text-line-through text-muted"
                  v-text=" fnFormatPrice(productInfo.price, productInfo.currency) " v-show="discount.percent"></span>
                <span v-show="discount.percent"> - </span>
                <strong class="text-success" v-if="!discount.is_amount"
                  v-text=" fnFormatPrice( ( productInfo.price - ( discount.percent * productInfo.price / 100  ))  , productInfo.currency ) "></strong>
                <strong class="text-success" v-else
                  v-text=" fnFormatPrice( ( productInfo.price - discount.percent) , productInfo.currency ) "></strong>

              </p>
            </div>
            <hr>
          </div>

          <div class="col-12 col-sm-6 ">
            <div class="form-group">
              <label for="" v-text=" $t('general.form.initialDate') "></label>
              <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.initialDate') ">
                <v-date-picker output-format='x' :formatted="CONST.DATE_FORMATS.DD_MM_YYYY" lang="en"
                  @input="fnDateNotBeforeTo() ; fnStringDates()" right v-model="discount.from" :min-date=" CONST.TODAY "
                  no-header only-date no-button-now dark no-button auto-close>
                  <input type="text" class="form-control" v-model=" stringDates.start ">
                </v-date-picker>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>

              </v-validation>
            </div>
          </div>
          <div class="col-12 col-sm-6 ">
            <div class="form-group">
              <label for="" v-text=" $t('general.form.finishDate') "></label>
              <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.finishDate')">

                <v-date-picker output-format='x' :formatted="CONST.DATE_FORMATS.DD_MM_YYYY" right
                  @input=" fnStringDates() " v-model="discount.to" :min-date="notBefore" no-header only-date
                  no-button-now dark no-button auto-close>
                  <input type="text" class="form-control" v-model=" stringDates.end " :disabled=" discount.unlimit ">
                </v-date-picker>

                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </v-validation>
            </div>
          </div>
          <div class="col-auto ml-auto">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="isUnlimited" v-model="discount.unlimit">
              <label class="form-check-label" for="isUnlimited" v-text="$t('general.form.unlimited')"> </label>
            </div>
          </div>
        </div>

        <v-validation :rules="{'required':variants.length > 0}" v-slot="{ errors }"
          :name=" $t('tables.selectVariants') " v-show="variants.length > 0">
          <div class="">
            <table class=" table table-striped table-hover ">
              <thead>
                <tr>
                  <th> <input type="checkbox" id="selectAllVAriants" :checked="selectAllVariants"
                      @click=" fnSelectAllVariants() ">
                  </th>
                  <th class="col">
                    <label :for=" 'selectAllVAriants' " class=" m-0" v-text=" $t('tables.selectVariants') ">
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for=" (variant,index) in variants  " :key=" index ">
                  <td>
                    <input type="checkbox" v-model="discount.variant_ids" :value="variant.id" :id="variant.id">
                  </td>
                  <td>
                    <label :for=" variant.id " class="m-0 w-100">
                      <p class="m-0">
                        <span v-text=" $t('tables.product') + ': ' "></span>
                        <strong v-text=" variant.name "></strong>
                      </p>
                      <p class="m-0 d-none"> <span v-text=" $t('tables.discount') + ': ' "></span>
                        <strong v-text=" (variant.discount_percent ? variant.discount_percent : 0) + '%' "></strong>
                      </p>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="bg-danger px-2" v-if="errors.length > 0">
            {{ errors[0] }}
          </div>
        </v-validation>
        <input type="submit" hidden>
      </v-observer>
    </div>
    <template v-slot:modal-footer>
      <div class="d-flex w-100">
        <b-button variant="primary" class=" mr-2" @click=" step = step - 1 " v-text=" $t('general.button.back') "
          v-if="( ((discount.id != null) && (step > 1)) || ( (discount.id == null) && (step > 2)) )   ">
        </b-button>
        <div class="ml-auto">
          <b-button variant="none" class="" @click=" modalDiscount = false ; fnResetProductDiscount() "
            v-text=" $t('general.button.close') ">
          </b-button>
          <b-button variant="success" class="" @click=" fnNextStep() " :disabled="sendingData">
            <span v-text=" (step < 2) ? $t('general.button.updateDiscount') : $t('general.button.update') "
              v-if=" !sendingData "></span>
            <i class="fa fa-spinner fa-spin " v-else></i>
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    name: "ProductDiscountModal",
    props: {
      productInfo: {
        type: Object,
      },
      showModalDiscount: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      DatePicker,
    },
    computed: {
      selectAllVariants() {
        return this.discount.variant_ids.length == this.variants.length;
      },
    },
    watch: {
      showModalDiscount() {
        this.modalDiscount = true;
      },
      productInfo() {

        if (typeof (this.productInfo.variants) == 'object') {
          this.productInfo.variantsList = this.productInfo.variants;
        }

        if (this.productInfo.variantsList && this.productInfo.variantsList.length > 0) {
          this.variants = this.productInfo.variantsList;
        } else {
          this.variants = [];
        }
        let tempDiscount = this.productInfo.discounts.find(disc => disc.active);
        if (this.productInfo.discounts && this.productInfo.discounts.length > 0 && tempDiscount) {
          this.discount.id = tempDiscount.id;
          this.discount.unlimit = tempDiscount.unlimit || false;
          this.discount.percent = tempDiscount.percent;
          this.discount.is_amount = tempDiscount.is_amount || false;
          this.discount.from = this.$moment(tempDiscount.from).format('x');
          this.discount.to = this.$moment(tempDiscount.to).format('x');
          // Variants
          if (tempDiscount.variant_ids) {
            let tempVarList = [];
            tempDiscount.variant_ids.map(varId => {
              if (this.variants.find(variant => variant.id == varId)) {
                tempVarList.push(varId);
              }
            });
            tempDiscount.variant_ids = tempVarList;
          }
          this.discount.variant_ids = tempDiscount.variant_ids;

          this.fnStringDates();
        } else {
          if (this.productInfo.variantsList.length == 0) {
            this.fnResetProductDiscount(2);
          } else {
            this.fnResetProductDiscount(2);
          }
        }
      }
    },
    data() {
      return ({
        percentages: [{
            value: 10,
            label: '10%'
          },
          {
            value: 25,
            label: '25%'
          },
          {
            value: 50,
            label: '50%'
          },
        ],
        dates: [{
            value: 7,
            label: this.$tc('messages.days', 1, {
              days: 7
            }),
          },
          {
            value: 30,
            label: this.$tc('messages.days', 1, {
              days: 30
            }),
          },
          {
            value: 120,
            label: this.$t('general.form.unlimited'),
          },
        ],
        quickDiscount: {
          discount: null,
          date: null,
        },
        modalDiscount: false,
        notBefore: null,
        sendingData: false,
        stringDates: {
          start: null,
          end: null,
        },
        discount: {
          id: null,
          percent: null,
          is_amount: false,
          from: null,
          to: null,
          unlimit: false,
          variant_ids: [],
        },
        variants: [],
        // steps
        step: 1,

      })
    },
    methods: {
      async fnApiAddDiscount() {

        if (await this.$refs['discountForm'].validate()) {
          this.sendingData = true;
          let {
            percent,
            from,
            to,
            variant_ids,
            unlimit,
            is_amount,
          } = this.discount;
          let tempDiscount = {
            percent,
            from,
            unlimit,
            is_amount,
          };
          if (variant_ids.length > 0) {
            tempDiscount.variant_ids = variant_ids;
          };
          if (tempDiscount.unlimit) {
            tempDiscount.to = tempDiscount.from;
          } else {
            tempDiscount.to = to;
          }
          if (this.discount.id) {
            await axios.put(`products/${this.productInfo.id}/discounts/${this.discount.id}`,
              tempDiscount).then((response) => {
              this.$toasted.global.infoMessage(this.$t('toasted.info.productDiscountUpdated'));
              this.$emit('update-discount', response.data, tempDiscount.variant_ids);
              this.modalDiscount = false;
              this.fnResetProductDiscount();
            }).catch(error => {});
          } else {
            await axios.post(`products/${this.productInfo.id}/discounts`, tempDiscount).then((
              response) => {
              this.$toasted.global.infoMessage(this.$t('toasted.info.productDiscountUpdated'));
              this.$emit('update-discount', response.data, tempDiscount.variant_ids);
              this.modalDiscount = false;
              this.fnResetProductDiscount();
            }).catch(error => {});
          }
          this.sendingData = false;
        } else {}
      },

      fnResetProductDiscount(step) {
        if (step) {
          this.step = step;
        } else {
          this.step = 1;
        }
        this.discount.variant_ids = [];
        this.discount.id = null;
        this.discount.is_amount = false;
        this.discount.percent = 20;
        this.discount.from = new Date().setHours(0, 0, 0, 0);
        this.discount.to = this.$moment(this.discount.from).add(30, 'days').format('x');
        this.discount.from = this.$moment(this.discount.from).format('x');
        this.quickDiscount.date = null;
        this.quickDiscount.discount = null;
        this.fnDateNotBeforeTo();
      },
      fnDateNotBeforeTo() {
        this.notBefore = this.$moment(this.discount.from, 'x').add(1, 'days').format();
        this.fnDateResetFinishDate();
        this.fnStringDates();
      },
      fnDateResetFinishDate() {
        this.discount.to = this.$moment(this.discount.from, 'x').add(30, 'days').format('x');
      },
      fnStringDates() {
        this.stringDates.start = this.$moment(this.discount.from, 'x').format(this.CONST.DATE_FORMATS
          .DD_MM_YYYY);
        this.stringDates.end = this.$moment(this.discount.to, 'x ').format(this.CONST.DATE_FORMATS.DD_MM_YYYY);
      },
      // Steps
      async fnNextStep() {
        switch (this.step) {
          case 1:
            this.step++;
            break;
          case 2:
            if (await this.$refs['formCreateDiscountVariants'].validate()) {
              this.discount.percent = this.quickDiscount.discount;
              this.discount.is_amount = false;
              this.discount.from = new Date().setHours(0, 0, 0, 0);
              this.discount.to = this.$moment(this.discount.from).add(this.quickDiscount.date, 'days').format('x');
              if (this.quickDiscount.date < 120) {
                this.discount.unlimit = false;
              } else {
                this.discount.unlimit = true;
              }
              this.discount.from = this.$moment(this.discount.from).format('x');
              if (this.productInfo.variantsList && this.productInfo.variantsList.length > 0) {
                this.discount.variant_ids = this.productInfo.variantsList.map(variant => variant.id);
              } else {
                this.discount.variant_ids = []
              }
              this.fnApiAddDiscount();
            }
            break;
          case 3:
            this.fnApiAddDiscount();
            break;
        }
      },

      fnSelectAllVariants() {
        if (this.discount.variant_ids.length < this.variants.length) {
          this.discount.variant_ids = [];
          for (let index in this.variants) {
            this.discount.variant_ids.push(this.variants[index].id);
          }
        } else {
          this.discount.variant_ids = [];
        }
      },
    },
    async mounted() {

    }
  }
</script>

<style>

</style>